.countryTable
    padding: 1rem
    .headerWrapper
        display: flex
        justify-content: space-between
        padding-bottom: 1rem
        border-bottom: 1px solid rgba(76, 78, 100, 0.12)
        >button
            white-space: nowrap

    .contentWrapper
        padding: 1rem 0

        > div
            padding-bottom: 1rem
            &:not(:first-child)
                padding-top: 1rem
                border-top: 1px solid rgba(76, 78, 100, 0.12)
