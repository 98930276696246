.CountryItem
    border: 1px solid rgba(76, 78, 100, 0.12)
    border-radius: 8px
    padding: 16.5px 14px
    cursor: default
    overflow: hidden
    &:not(:first-child)
        padding-top: 16px

    .header
        display: flex
        align-items: center
        >svg
            margin-right: 10px
            cursor: grab
            outline: none

        >div
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%

    .body
        height: 100%
        height: 0
        display: none
        transition: visibility 0.3s ease-in-out, margin 0.3s ease-in-out, height 0.3s ease-in-out
        overflow: none
        gap: 10px

        .buttonWrapper
            display: flex
            justify-content: center

        >button
            margin: 0 auto

.SubBenefitWrapper
    padding: 0 1rem

    .headerWrapper
        display: flex
        align-items: center

        .imgPlaceHolder
            margin-right: 10px
            height: 25px
            width: 25px

        img
            margin-right: 10px
            height: 25px
            width: 25px
            object-fit: cover

    .body
        padding: 0 2rem

        .imagePreview
            display: flex
            margin: 0 auto

.activeMenu
    .body
        margin-top: 16.5px
        height: 100%
        display: grid

.mandatory
    display: flex
    align-items: center
