.wrapper
    text-decoration: none
    display: flex

    .boxWrapper
        position: relative
        display: flex
        align-items: center
        cursor: pointer
        border-radius: 8px
        margin: 0px 0 5px 5px
        padding: 7px 12px
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,

        &:hover
            background-color: rgba(102, 108, 255, 0.08)

    .showToolTip

        &::after
            content: "Loading..."
            position: absolute
            bottom: 50px
            background-color: #666CFF9f
            color: #ffffff
            padding: 7px
            margin-left: 5px
            border-radius: 8px
            height: 30px
            left: 50%
            transform: translate(-50%, 0)
            display: flex
            align-items: center
            justify-content: center

        &::before
            content: ""
            position: absolute
            bottom: 30px
            left: 50%
            transform: translate(calc(-50% + 5px), 0)
            width: 0
            height: 0
            border-left: 10px solid transparent
            border-right: 10px solid transparent
            border-bottom: 10px solid transparent
            border-top: 10px solid #666CFF9f
