
.wrapperClass
    padding: 1rem
    border: 1px solid #ccc

.editorClass
    padding: 1rem
    border: 1px solid #ccc
    height: 50vh

.toolbarClass
    border: 1px solid #ccc

.rdw-link-modal
    height: 220px

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content
    height: unset !important
    max-height: 100% !important

.DraftEditor-root
    height: 95% !important
