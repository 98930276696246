
.statistics_content_wrapper
    display: flex
    flex-direction: column
    align-items: center
    margin: 00px 0px 50px 0px

    h4
        text-align: center
        font-size: 25px
    .statistics_data_wrapper
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 50px
        .statistics_data_elements
            border: 2px solid black
            border-radius: 8px
            padding: 10px
            p
                text-align: center
                &:not(:first-child)
                    font-size: 35px
                    font-style: italic
                &:first-child
                    font-weight: 900
