.filterWrapper
    display: "flex"
    align-items: "center"
    padding: "10px"
    z-index: "100"
    border-radius: "4px"
    box-shadow: "3px 3px 7px 0px black"
    position: "absolute"
    bottom: "-80px"
    left: -123px
    > div
        width: 90px
        &:nth-child(n+3)
            min-width: 100px
