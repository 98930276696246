.StatisticsComponent_wrapper
    padding: 20px
    margin: 20px 20px 20px 0
    background-color: #ffffff
    border-radius: 4px
    // display: flex
    .statistics_content_wrapper
        display: flex
        flex-direction: column
        align-items: center
        position: relative
        // left: 15%

        h4
            text-align: center
            font-size: 25px
        .statistics_data_wrapper
            display: grid
            grid-template-columns: repeat(2, 1fr)
            gap: 50px
            .statistics_data_elements
                border: 2px solid black
                border-radius: 8px
                p
                    text-align: center
                    margin-block-start: 1em
                    margin-block-end: 1em
                    &:not(:first-child)
                        font-size: 35px
                        font-style: italic
                    &:first-child
                        font-weight: 900
