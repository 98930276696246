.wrapper
    display: flex
    margin: 1rem 1rem 0 0
    cursor: pointer
    background-color: #666CFF5f
    padding: 8px
    border-radius: 8px
    color: #ffffff

    &:hover
        background-color: #666CFF9f

    p
        color: #ffffff
        margin: 0 0 0 4px
